import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import cgcCoverVideoFnB from "../assets/cgc-cover-video-fnb.mp4";
import cgcCoverAlternate from "../assets/cgc-cover-alternate.jpg";
import StyledList from "../shared-components/generic/StyledList";
import StyledButton from "../shared-components/generic/StyledButton";
import {ModalContext} from "../contexts/ModalContext";
import QuickTips from "./QuickTips";

const LandingPage = () => {
    const {
        appLang,
        brandId,
        name,
        welcomeMsg,
        incentiveStatement,
        secondIncentive,
        coverImageUrl,
        industryCode,
        gamificationActive,
    } = useContext(GeneralContext);
    const {setUploadMediaModalOpen, setFromLandingPage} = useContext(ModalContext)
    const content = language[appLang];

    const items = [
        !!welcomeMsg ? welcomeMsg : Utility.capitalize(content.SNAP_AND_UPLOAD_SENTENCE_ALT),
        Utility.capitalize(incentiveStatement),
        Utility.capitalize(content.WELL_SPREAD_LOVE_SENTENCE) + "!"
    ];
    if (!!secondIncentive) {
        items.push(Utility.capitalize(secondIncentive));
    }

    const handleInitiateUpload = () => {
        setUploadMediaModalOpen(true);
        setFromLandingPage(true);
    }

    return (
        <div className="landing-page">
            {!!brandId
                ? <>
                    <div className="cgc-cover-container">
                        {Utility.renderMedia(
                            !!coverImageUrl
                                ? coverImageUrl
                                : !industryCode
                                    ? cgcCoverVideoFnB
                                    : cgcCoverAlternate,
                            content.IMAGE_OF_SOMEONE_TAKING_A_PICTURE_OF_FOOD,
                            null,
                            "cgc-cover"
                        )}
                    </div>
                    <div className={`main-container${gamificationActive ? " gamification-mode" : ""}`}>
                        {gamificationActive
                            ? <>
                                <div className="big-text">
                                    <span>{Utility.capitalize(content.SNAP_A_PHOTO_SENTENCE)}!</span>
                                </div>
                                <QuickTips/>
                            </>
                            : <>
                                {!!name
                                    ? <div className="big-text">
                                        <span>{Utility.createVariableSentenceSpan(Utility.capitalize(content.SHARE_THE_LOVE_FOR_$$BRAND_NAME$$), new Map([["brandName", name]]))}!</span>
                                    </div>
                                    : <div className="big-text">
                                        <span>{Utility.capitalize(content[!industryCode ? "LOVE_OUR_FOOD" : "LOVE_OUR_EXPERIENCE"])}?</span>
                                        <span>{Utility.capitalize(content.SHARE_THE_JOY)}!</span>
                                    </div>
                                }
                                <StyledList
                                    className="small-text"
                                    numbered
                                    items={items}
                                />
                            </>
                        }
                        <div className="bottom-section">
                            <StyledButton
                                type="main"
                                onClick={handleInitiateUpload}
                            >
                                {Utility.capitalize(content.GET_STARTED)}
                            </StyledButton>
                        </div>
                    </div>
                </>
                : null
            }
        </div>
    );
};

export default LandingPage;