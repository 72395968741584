import React, {useContext} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {ModalContext} from "../../contexts/ModalContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import StyledNotification from "../../shared-components/generic/StyledNotification";

const RedeemPopupModal = () => {
    const {
        appLang,
        orderingAppUrl,
    } = useContext(GeneralContext)
    const {redeemPopupModalOpen, setRedeemPopupModalOpen} = useContext(ModalContext);
    const content = language[appLang];

    const handleClose = () => {
        setRedeemPopupModalOpen(false);
    }

    const handleRedeemAction = () => {
        if (orderingAppUrl) {
            window.open(orderingAppUrl, '_blank');
        }

        handleClose();
    }

    const renderText = () => {
        return (
            <div className="big-text redeem-popup-text">
                    <span>{Utility.capitalize(content.YOUR_DISCOUNT_CODE_SENTENCE)}</span>
            </div>
        )
    }

    return (
        <StyledNotification
            open={redeemPopupModalOpen}
            onClose={handleClose}
            className="redeem-popup-modal"
            renderNotification={renderText}
            cancelText=""
            cancelAction={null}
            acceptText={Utility.capitalize(content.GOT_IT)}
            acceptAction={handleRedeemAction}
        />
    )
}

export default RedeemPopupModal;