import React, {createContext, useState} from "react";

export const ModalContext = createContext(null);

export const ModalProvider = (props) => {
    const [videoNudgeModalOpen, setVideoNudgeModalOpen] = useState(false);
    const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
    const [fromLandingPage, setFromLandingPage] = useState(false);
    const [redeemPopupModalOpen, setRedeemPopupModalOpen] = useState(false);

    return (
        <ModalContext.Provider value={{
            videoNudgeModalOpen,
            uploadMediaModalOpen,
            fromLandingPage,
            redeemPopupModalOpen,

            setVideoNudgeModalOpen,
            setUploadMediaModalOpen,
            setFromLandingPage,
            setRedeemPopupModalOpen,
        }}>
            {props.children}
        </ModalContext.Provider>
    )
}