// noinspection DuplicatedCode

import enums from '../../enums.json';
const {languages} = enums;

export const language = {
    [languages.ENGLISH]: {
        A_AFTER_GETTING_YOUR_CONSENT_SENTENCE: "A: After getting your consent, we (the business/brand) may select the best content to share with more audiences through our social media. This will help fellow foodies discover our delights!",
        A_EVERY_MONTH_WE_KEEP_TRACK_SENTENCE: "A: Every month, we keep track of the published content’s engagement on social media. If yours get the most likes and re-posts you can redeem free meals at your next visit!",
        A_IT_IS_VERY_SIMPLE_SENTENCE: "A: It's very simple! If you're the winner, we (the business) will email or text you, based on the contact info you shared with us. Then, on your next visit, you can simply show your email or text message to claim your free meal! You can also bring your friends and family here to create content, the more/better you create, the more likely you get more free meals!",
        A_THE_CONTENT_IS_OWNED_SENTENCE: "A: The content is jointly owned by the business/brand and the customer (you). This allows both parties to publish the content on social media and websites, and to share it with friends and family.",
        A_VIDEO_IS_WORTH_A_THOUSAND_PHOTOS: "A video is worth a thousand photos",
        ADD_A_LINK_FOR_THIS_IMAGE_VIDEO: "add a link for this image/video",
        ADD_ANOTHER_ITEM: "add another item",
        ADD_TO_HOME_SCREEN: "add to home screen",
        ADD_YOUR_CAPTION_FOR_THIS_POST: "add your caption for this post",
        ADMIN_LOGIN: "admin log in",
        ALL_IMAGES_INELIGIBLE_SENTENCE: "Hmm, it appears that one or more of your uploaded media do not fit our expectations. We recommend you include full or un-eaten dishes, or videos that clearly display the environment.",
        ANONYMOUS: "anonymous",
        ANONYMOUS_ATTENDEE: "anonymous attendee",
        ANYTHING_YOU_WANT_TO_SAY: "anything you want to say",
        ATTENDEE_INPUT_COLLECTION: "attendee input collection (eg. request contact)",
        BRAND_COLORS: "brand colors",
        BRAND_DESCRIPTION: "brand description",
        BRAND_LOGO: "brand logo",
        BRAND_NAME: "brand name",
        BRAND_SETUP: "brand setup",
        BRAND_TONE: "brand tone",
        CAMERA_AND_MICROPHONE_PERMISSIONS_NEEDED_TO_RECORD_VIDEO: "Camera and microphone permissions are required to record video.",
        CAPTION_OPTIONAL: "caption (optional)",
        CAPTURE_AND_SHARE_YOUR_MOMENT: "capture and share your moment",
        CAPTURE_CANDID_MOMENTS_WITH_$$BRAND_NAME$$: "capture candid moments with $$brandName$$",
        CAPTURE_FRESH_MOUTHWATERING_DISHES: "capture fresh, mouthwatering dishes",
        CAPTURE_FUN_VIBRANT_AND_GENUINE_MOMENTS: "capture fun, vibrant, and genuine moments",
        CAPTURE_SHORT_VIDEOS_THAT_POP: "capture short videos that pop",
        CAPTURE_THE_MOMENT: "capture the moment",
        CHECK_YOUR_EMAIL_$$LOGIN_EMAIL$$_SENTENCE: "Check your email ($$loginEmail$$) to get your access link",
        CHOOSE_TOPICS_SENTENCE: "choose the topics for the next campaign's posts",
        CLEAR_AND_HIGH_QUALITY_VISUALS: "clear and high quality visuals",
        COMPLETE_BRAND_SETUP: "complete brand setup",
        COMPLETE_REDEMPTION: "complete redemption",
        CONFIRM_DELETE_POST_SENTENCE: "This will remove the post from any upcoming schedule. This action cannot be undone. Do you want to continue?",
        CONFIRM_GENERATE_TOPICS_SENTENCE: "Generate new topics for the scheduled campaign? (This will override all current topics)",
        CONFIRM_GENERATE_POSTS_SENTENCE: "Generate new posts for each date and topic in this list? (This will remove previously created posts scheduled for this date range)",
        CONFIRM_RESET_CALENDAR_SENTENCE: "This will create an new calendar using your chosen posting schedule, any current topics will be reset to match the new calendar. Proceed?",
        CONFIRM_URL_OVERRIDE: "This will override current brand data and content strategy if saved.",
        CONGRATS_ON_SETTING_UP_YOUR_ACCOUNT: "congrats on setting up your account",
        CONTACT_ENTERED_INTO_RAFFLE_SENTENCE: "We’ve entered you into the raffle—good luck!",
        CONTENT: "content",
        CONTENT_CALENDAR: "content calendar",
        CONTENT_NEEDS_FLAVOR_SENTENCE: "it looks like your content could use a little more flavor",
        CONTENT_PILLARS: "content pillars",
        CONTENT_STRATEGY: "content strategy",
        CONTENT_VOICE: "content voice",
        CREATE: "create",
        CREATE_APPLICATION: "create application",
        CREATE_CALENDAR: "create calendar",
        CREATE_NEW_BRAND: "create new brand",
        CURATE: "curate",
        DATE: "date",
        DELETE: "delete",
        DELETE_IMAGE: "delete image",
        DELETE_POST: "delete post",
        DIDNT_RECEIVE_THE_EMAIL: "Didn't receive the email?",
        DO_NOT_POST_ON_THIS_DAY: "do not post on this day",
        DONE: "done",
        DONT_FORGET_WE_PICK_A_WINNER_SENTENCE: "don't forget, whoever uploads the best video or photo to gets a free meal!",
        DONT_FORGET_WE_PICK_A_WINNER_SENTENCE_ALT: "don't forget, whoever uploads the best video or photo to gets a prize!",
        DONT_MISS_YOUR_CHANCE_TO_WIN: "Don't miss your chance to win!",
        DOWNLOAD: "download",
        EDIT: "edit",
        EDIT_POSTS_SENTENCE: "create or generate posts for the upcoming schedule",
        EMAIL: "email",
        EMAIL_VALIDATED: "email validated",
        ENCOURAGE_SHARE_VIDEO_STATEMENT: "Could you help others discover their next meal with a quick video? Even a 3-second-video can make a big difference!",
        ENTER_A_RAFFLE_TO_WIN_SWAG: "enter a raffle to win swag",
        ENTER_A_TOPIC: "enter a topic",
        ERROR_VALIDATING_CODE: "there was an error in validating the code, please try again later",
        EVENT_ORGANIZER: "event organizer",
        EVERY_10_POSTS: "every 10 posts",
        EVERY_20_POSTS: "every 20 posts",
        EVERY_40_POSTS: "every 50 posts",
        FACEBOOK: "Facebook",
        FAQ: "FAQ",
        FILE_TOO_LARGE: "The file is too large, try to keep videos to 10 seconds or less.",
        FIRST_CHOOSE_YOUR_POST_FORMAT: "first, choose your post format",
        FIRST_LETS_SET_UP_YOUR_ACCOUNT: "first, let's set up your account",
        FIRST_TIER_REWARD: "first-tier reward",
        FOLLOW_US_ON: "follow us on",
        FOR_ADMIN_ACCESS_SENTENCE: "For admin access, please enter your email as an event organizer for this event",
        FORGOT_TO_ADD_CONTACT_SENTENCE: "forgot to add your contact? No problem, just go back, add it and click 'done', we'll get you connected to your awesome entry.",
        FRI: "Fri",
        FROM_YOUR_EXISTING_WEBSITE: "from your existing website",
        GENERATE_CONTENT_VOICE_EXAMPLES: "generate content voice examples",
        GENERIC_IMAGE_OF_AN_INPUT_BOX: "generic image of an input box",
        GENERIC_IMAGE_OF_MEDIA: "generic image of media",
        GENERIC_IMAGE_OF_SOCIAL_MEDIA_LINKS: "generic image of social media links",
        GET_DISCOUNT_SENTENCE: "get a discount or free item from us",
        GET_ONE_TIME_CODE: "get one-time code",
        GET_STARTED: "get started",
        GET_STARTED_SENTENCE: "To get started, please take about 10 minutes to share more with us about your needs",
        GET_SUGGESTED_TOPICS: "get suggested topics",
        GET_YOUR_FIRST_TIER_REWARD: "show this screen to your server or cashier to claim your first-tier reward",
        GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US: "show this screen to your server or cashier to claim your reward",
        GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US_ALT: "show this screen to our staff to claim your reward",
        GET_YOUR_SECOND_TIER_REWARD: "show this screen to your server or cashier to claim your second-tier reward",
        GIVE_IT_ANOTHER_SHOT_SENTENCE: "Give it another shot! We can't wait to see your next submission!",
        GOOGLE_MAPS_SEARCH: "google search/maps",
        GOOGLE_REVIEW: "Google Review",
        GO_BACK: "back",
        GO_TO_SIGN_UP: "go to sign up",
        GO_TO_LOGIN: "go to login",
        GOT_IT: "got it",
        HIGHLIGHT_GENUINE_HAPPY_VIBES: "highlight genuine happy vibes",
        HOW_DID_YOU_HEAR_ABOUT_US: "how did you hear about us?",
        I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$: "I accept the $$termsAndConditions$$",
        IF_YOUR_CREATIONS_SENTENCE: "If your creations shine, we will reach out not just for your free meal, but also for future collaboration opportunities!",
        IM_DONE_FOR_NOW: "I'm done for now",
        IMAGE: "image",
        IMAGE_OF_SOMEONE_TAKING_A_PICTURE_OF_FOOD: "image of someone taking a picture of food",
        IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA: "image of a thank you in a square surrounded by social media icons",
        IMAGE_OF_UNHAPPY_FACE_WITH_IMPROVEMENTS_NEEDED_MESSAGE: "image of an unhappy emoji with the text: improvements needed",
        IMAGE_UPLOAD_ERROR_SENTENCE: "There was a problem saving the image or video.",
        IMAGES_REJECTED_ALTERNATE_TEXT: "Almost there! To help others feel the vibes, could you capture some exciting, relevant moments? Let’s make this place shine!",
        IMAGES_REJECTED_TEXT: "Almost there! To help others feel the flavor, could you capture some fresh, tasty dishes in the restaurant? Let’s get those mouths watering!",
        IMPORT_BRAND: "import brand",
        IMPORT_YOUR_BRAND: "import your brand",
        INPUT_YOUR_WEBSITE_URL_HERE: "input your website URL here",
        INSTAGRAM: "Instagram",
        ITEM: "item",
        KEEP_SNAPPING: "keep snapping",
        KEEP_SPREADING_SENTENCE: "share your experience",
        KEEP_SPREADING_THE_FOODIE_LOVE_AND_TAG_US_AT_$$HANDLE$$: "share your experience and tag us at $$handle$$",
        LETS_GET_STARTED: "let's get started",
        LEVERAGE_SHORT_VIDEOS: "videos are worth a thousand pictures",
        LOADING: "loading",
        LOG_IN: "log in",
        LOG_IN_WITH_GOOGLE: "log in with Google",
        LOG_OUT: "log out",
        LOGIN: "login",
        LOGO: "logo",
        LOVE_BEING_IN_THE_SPOTLIGHT_SENTENCE: "Love being in the spotlight? Share your social media handle with us, and get ready to be tagged in all the delicious moments you created with us! Let's spread the joy of good food together!",
        LOVE_OUR_EXPERIENCE: "love our experience",
        LOVE_OUR_FOOD: "love our food",
        MAILER: "Flyer/printed Ads",
        MAKE_IT_CLEAR: "Make It Clear",
        MAKE_SURE_YOUR_VISUALS_ARE_CLEAR: "make sure your visuals are clear",
        MEDIA_PLUS_CAPTION_PLUS_LINK: "media + caption + link",
        MIX_IT_UP_WITH_VARIETY: "mix it up with variety",
        MON: "Mon",
        MORE_CASUAL: "more casual",
        MORE_PROFESSIONAL: "more professional",
        NEXT: "next",
        NEXT_TIME: "next time",
        ONCE_AS_NORMAL_POSTS: "once (as normal post)",
        OTHER: "other",
        PASSWORD: "password",
        PHYSICAL_PROXIMITY: "Drove/walked by",
        PLEASE_PROVIDE_YOUR_EMAIL_SENTENCE: "Please provide your email to receive a link to all the fun moments. We will also notify you if you win the raffle drawing!",
        PLEASE_READ_TERMS_SENTENCE: "please read these terms and conditions carefully before using this site",
        PLEASE_USE_CURRENT_MEDIA: "please use media current to this event",
        POST: "post",
        POST_IMAGE: "post image",
        POSTING_SCHEDULE: "posting schedule",
        PREVIEW: "preview",
        PREVIEW_YOUR_POST: "preview your post",
        PRIMARY_COLOR: "primary color",
        PROBLEM_DELETING_POST_SENTENCE: "There was a problem deleting your post, we apologize for the inconvenience and recommend reloading and trying again.",
        Q_HOW_DO_I_KNOW_SENTENCE: "Q: How do I know if I am the winner? And how do I redeem it?",
        Q_HOW_DO_YOU_SELECT_THE_WINNERS: "Q: How do you select the winners?",
        Q_HOW_WILL_MY_CONTENT_BE_USED: "Q: How will my content be used?",
        Q_WHO_OWNS_THE_CONTENT_I_CREATED: "Q: Who owns the content I created?",
        QUESTIONS_CHECK_OUT_OUR_$$FAQ$$: "Questions? Check out our $$faq$$",
        QUICK_TIPS: "quick tips",
        REDEEM_AWARD: "redeem award",
        REDEEM_FIRST_TIER_REWARD: "redeem first tier reward",
        REDEEM_NOW: "redeem now",
        REDEEM_SECOND_TIER_REWARD: "redeem second tier reward",
        REDEEMED: "redeemed",
        REGISTER: "register",
        REGISTRATION_COMPLETED_SENTENCE: "We're thrilled to support you as you spread your brand love! Get ready to use this app to access powerful resources and communicate with us.",
        RELEVANT_HASH_TAGS: "relevant hash tags",
        REMEMBER: "remember",
        REMEMBER_ME_ON_THIS_DEVICE: "remember me on this device",
        REMEMBER_ME_ON_THIS_DEVICE_FOR_30_DAYS: "remember me on this device for 30 days",
        REMEMBER_THE_BETTER_THE_QUALITY_SENTENCE: "remember, the better the quality, the bigger the reward",
        REQUIRED: "required",
        RESET_CALENDAR: "reset calendar",
        REVIEW_POSTS: "review posts",
        REWARD_REDEEMED_ALREADY: "the reward was redeemed already",
        SAT: "Sat",
        SAVE_ALL_AND_GENERATE_NEW_POSTS: "save all and generate new posts",
        SAVE_ALL_POSTS: "save all posts",
        SAVE_CHANGES: "save changes",
        SAVE_CONTENT_STRATEGY: "save content strategy",
        SAVE_CONTENT_CALENDAR: "save content calendar",
        SAVE_POST: "save post",
        SCALE_SOCIAL: "Scale Social",
        SCALE_SOCIAL_DESCRIPTION_SHORT: "your one-stop-shop social media marketing destination",
        SCALE_SOCIAL_LOGO: "scale social logo",
        SCAN_WEBSITE: "scan website",
        SCHEDULE_CONTENT: "schedule content",
        SECOND_TIER_REWARD: "second-tier reward",
        SECONDARY_COLOR: "secondary color",
        SELECT: "select",
        SELECT_DAYS_TO_POST_DURING_THE_WEEK: "select days to post during the week",
        SELECT_FROM_THE_LIST: "select from the list",
        SELECT_THE_CONTENT_VOICE_SENTENCE: "select the tone and voice you want reflected in your posts",
        SEND_IT_AGAIN: "send it again",
        SET_HOW_YOUR_NAME_WILL_BE_DISPLAYED: "set how your name will be displayed",
        SET_THE_FREQUENCY_FOR_THIS_POST: "set the frequency for this post",
        SHARE: "share",
        SHARE_AND_UPLOAD_YOUR_PHOTOS_OR_VIDEOS: "share and upload your photos or videos",
        SHARE_HAPPY_CUSTOMER_EXPERIENCES: "share happy customer experiences",
        SHARE_MORE: "share more",
        SHARE_MORE_WITH_US_OPTIONAL: "share more with us (optional)",
        SHARE_SOCIAL_HANDLE_FOR_RAFFLE_SENTENCE: "share your social handle so we can tag you if you win",
        SHARE_SOCIAL_HANDLE_MONTHLY_WINNER_SENTENCE: "share your social handle, and we'll announce the monthly winner on social media, who will get a free meal as a reward",
        SHARE_SOCIAL_HANDLE_MONTHLY_WINNER_SENTENCE_ALT: "share your social handle, and we'll announce the monthly winner on social media, who will get a prize",
        SHARE_SOCIAL_HANDLE_NO_MONTHLY_WINNER_SENTENCE: "share your social handle, and get ready to be tagged in all the delicious moments you created with us",
        SHARE_SOCIAL_HANDLE_NO_MONTHLY_WINNER_SENTENCE_ALT: "share your social handle, and get ready to be tagged in all the awesome moments you created with us",
        SHARE_THE_JOY: "share the joy",
        SHARE_THE_LOVE_FOR_$$BRAND_NAME$$: "share the love for $$brandName$$",
        SHARE_YOUR_CONTACT_DETAILS_TO_$$CGC_INCENTIVE$$: "share your contact details to $$cgcIncentive$$",
        SHARE_YOUR_CONTACT_INFO_TO_ENTER_RAFFLE: "share your contact info to enter raffle",
        SHOW_FRESH_MOUTHWATERING_DISHES: "show fresh, mouthwatering, dishes",
        SHOWCASE_THE_VIBES_AND_AMBIANCE: "capture fresh, mouthwatering dishes",
        SHOWCASE_THE_VIBES_AND_AMBIANCE_ALT: "capture exciting experiences",
        SIGN_IN: "sign in",
        SIGN_UP: "sign up",
        SIGN_UP_WITH_EMAIL_AND_PASSWORD: "sign up with email and password",
        SNAP_A_PHOTO_SENTENCE: "snap a photo or video and uncover your surprise rewards",
        SNAP_AND_UPLOAD_SENTENCE: "snap + upload tasty videos or photos",
        SNAP_AND_UPLOAD_SENTENCE_ALT: "snap + upload videos or photos of your experience",
        SO_YOUR_MOMENTS_REALLY_SHINE: "so your moments really shine",
        SOCIAL_MEDIA: "social media",
        SOCIAL_MEDIA_HANDLE: "social media handle (optional)",
        SOCIAL_MEDIA_LINKS: "social media links",
        SOCIAL_MEDIA_SETTINGS: "social media settings",
        SPICE_IT_UP: "Spice It Up",
        STANDARD: "standard",
        START_OVER: "start over",
        START_SHARING: "start sharing",
        SUBMIT: "submit",
        SUN: "Sun",
        SWITCH_BRANDS: "switch brands",
        TAKE_A_SCREENSHOT_SENTENCE: "take a screenshot and show it to the store server",
        TAKE_A_VIDEO: "take a video",
        TAKE_SHORT_VIDEOS: "take short videos",
        TAKE_VIDEO_QUESTION: "What do you want to share?",
        TAP_AND_HOLD_TO_SAVE_TO_YOUR_PHOTO_ALBUM: "tap + hold to download and re-share",
        TARGET_AUDIENCE: "target audience",
        TERMS_AND_CONDITIONS: "Terms and Conditions",
        THANK_YOU: "thank you",
        THANK_YOU_FOR_SHARING_YOUR_EXPERIENCE: "thank you for sharing your experience",
        THANK_YOU_SENTENCE: "Thanks so much for sharing your foodie content!",
        THANKS_AND_CONGRATS: "thanks and congrats",
        THANKS_FOR_SHARING: "thanks for sharing",
        THANKS_FOR_SHARING_SENTENCE: "Thank you for sharing!",
        THE_BETTER_THE_QUALITY_SENTENCE: "the better the quality, the bigger the reward",
        THE_BETTER_YOUR_UPLOAD_SENTENCE: "the better your upload, the bigger your reward",
        THERE_WAS_A_PROBLEM_DOWNLOADING_YOUR_CONTENT_SENTENCE: "There was a problem downloading your content. Please try again.",
        THERE_WAS_A_PROBLEM_SENDING_A_LOGIN_LINK_SENTENCE: "There was a problem sending a login link to that email address. Please confirm that you entered the event admin email correctly.",
        THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE: "There was a problem uploading your content. Please try again.",
        THIS_WILL_HELP_SENTENCE: "This will help fellow foodies discover our delights!",
        THIS_USER_GENERATED_CONTENT_SENTENCE: 'This User-Generated Content (UGC) platform ("Platform") is operated by Scale Social AI, LLC ("We" or "Us"). We are registered in the United States under the laws of North Carolina.',
        THU: "Thu",
        TIK_TOK: "TikTok",
        TIPS: "tips",
        TO_CLAIM_YOUR_REWARD: "to claim your reward",
        TO_CONTACT_US_PLEASE_EMAIL_$$CONTACT_EMAIL$$: "To contact us, please email $$contactEmail$$",
        TODAYS_DATE: "today's date",
        TOPIC: "topic",
        TRY_THESE_TIPS: "try these tips",
        TRY_IT_AGAIN: "try it again",
        TUE: "Tue",
        UNCOVER_MY_REWARDS: "uncover my rewards",
        UNDO_UPDATES: "undo updates",
        UNIQUE_CODE: "unique code",
        UNIQUE_SELLING_POINT: "unique selling point",
        UNNAMED_BRAND: "unnamed brand",
        UPLOAD_IMAGE: "upload image",
        UPLOAD_LOGO: "upload logo",
        UPLOAD_MEDIA_PLUS_CAPTION_PLUS_LINK: "upload media + caption + link",
        UPLOAD_YOUR_IMAGE_OR_VIDEO: "upload your image or video",
        URL_COPIED_TO_CLIPBOARD_SENTENCE: "media URL copied, select paste to use in your review",
        URL_OPTIONAL: "URL (optional)",
        URL_TO_YOUR_$$SOCIAL_MEDIA_NAME$$_PAGE: "URL to your $$socialMediaName$$ page",
        USE_THIS_CODE_$$PROMO_CODE$$_TO_GET_$$CUSTOM_REWARD$$: "use this code: $$promoCode$$ to get $$customReward$$",
        VALID_EMAIL_ADDRESS_REQUIRED: "valid email address required",
        VALIDATING_QR_CODE: "validating QR code",
        VERIFICATION_EMAIL_SENT: "verification email sent",
        VERIFY_EMAIL_ADDRESS: "verify email address",
        VERIFYING_SIGN_IN_SENTENCE: "verifying your sign-in, you will be logged in shortly",
        VIDEO: "video",
        VIDEOS_PLUS_PHOTOS: "Videos + Photos",
        VIEW: "view",
        VIEW_MY_SHARES: "view my shares",
        VIEW_OTHERS_MOMENTS_IN_A_LIVE_FEED: "view others' moments in a live feed",
        WANT_TO_AIM_HIGHER_PARAGRAPH: "Want to aim higher? Submit another photo or video with even better quality and creativity, and you may unlock an even bigger reward!",
        WANT_TO_TEAM_UP_SENTENCE: "Want to team up in the future? Drop your contact info, and we'll send more fun ways to boost your influence!",
        WED: "Wed",
        WED_LOVE_YOUR_HELP: "We'd love your help!",
        WELCOME_ABOARD: "welcome aboard",
        WELCOME_TO: "welcome to",
        WELCOME_TO_$$EVENT_NAME$$: "welcome to $$eventName$$",
        WELL_SPREAD_LOVE_SENTENCE: "we'll spread your love",
        WINNERS_GET_FREE_MEALS: "winners get free meals",
        WHO_WE_ARE_AND_HOW_TO_CONTACT_US: "who we are and how to contact us",
        WORD_OF_MOUTH: "word of mouth",
        WOULD_YOU_LIKE_YOUR_NAME_TO_SHINE: "would you like your name to shine",
        WRITE_YOUR_POST_HERE: "write your post here",
        YOUR_DISCOUNT_CODE_SENTENCE: "Discount code copied to your clipboard. Paste it in the order experience and get your rewards!",
        YOUR_EMAIL_ADDRESS: "your email address",
        YOUR_EMAIL_ADDRESS_USED_FOR_SCALE_SOCIAL: "your email address used for Scale Social",
        YOUR_EMAIL_OPTIONAL: "your email (optional)",
        YOUR_NAME: "your name",
        YOUR_NAME_DEFAULT_EVENT_ORGANIZER: "your name (default: Event organizer)",
        YOUR_NAME_OPTIONAL: "your name (optional)",
        YOUR_PHONE_NUMBER_OR_EMAIL: "your phone number or email (optional)",
        YOUR_THOUGHTS_OR_COMMENTS_OPTIONAL: "your thoughts or comments (optional)"
    }
}