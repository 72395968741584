import React, {useState, useRef, useEffect} from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledSelect = (props) => {
    const {
        className,
        options = [],
        value,
        onChange,
        placeholder = "Select an option",
        header,
        headerTextClass,
        errorMessage,
        hasError,
        brandColorOverride,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleOptionClick = (option) => {
        onChange(option.value);
        setIsOpen(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            setIsOpen((prev) => !prev);
        }
    };

    return (
        <div
            ref={selectRef}
            className={`styled-select${className ? " " + className : ""}`}
            aria-expanded={isOpen}
            tabIndex={0}
            role="combobox"
            onKeyDown={handleKeyDown}
        >
            {!!header && (
                <div className={`select-header${headerTextClass ? " " + headerTextClass : ""}`}>
                    {header}
                </div>
            )}
            <div
                className={`custom-select ${hasError ? " has-error" : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={`select-display${!!value ? "" : " placeholder"}`}>
                    {value ? options.find(option => option.value === value)?.label : placeholder}
                </div>
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>
            {isOpen && (
                <div className="select-dropdown">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`select-option ${option.value === value ? "selected" : ""}`}
                            onClick={() => handleOptionClick(option)}
                            style={brandColorOverride && option.value === value ? {background: brandColorOverride} : null}
                            role="option"
                            aria-selected={option.value === value}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
            {hasError && <div className="error-message">{errorMessage}</div>}
        </div>
    );
};

export default StyledSelect;