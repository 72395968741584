import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {ModalContext} from "../contexts/ModalContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import thankYouMedia from "../assets/thank-you-media.png";
import firstTierThankYouMedia from "../assets/first-tier-thank-you-media.png";
import StyledButton from "../shared-components/generic/StyledButton";
import RenderRedeemQrCode from "./gamificationResultPages/RenderRedeemQrCode";

const ThankYouPage = () => {
    const {
        appLang,
        thankYouDts,
        brandId,
        industryCode,
        hasMainRewardAsRaffle,
        thankYouImageUrl,
        userEmail,
        userHandle,
        goToViewMyShares,
        goToNewStage,
        gamificationActive,
        validationCode,
        currentPromoCode,
        customThankYouText,
        customThankYouRewardText,
        promoTerms,
    } = useContext(GeneralContext);
    const {setRedeemPopupModalOpen} = useContext(ModalContext);

    const content = language[appLang];

    const showForgotToAddContact = !userEmail && !userHandle;
    const usePromo = !!currentPromoCode;

    const handleRedeemNow = () => {
        setRedeemPopupModalOpen(true);
        if (currentPromoCode) {
            navigator.clipboard.writeText(currentPromoCode).then(() => {
                console.log('Promo code copied to clipboard');
            }).catch(err => {
                console.error('Failed to copy promo code: ', err);
            });
        }
    }

    return (
        <div className="thank-you-page">
            <div className="thank-you-date additional-text">
                {Utility.capitalize(content.TODAYS_DATE)}: {Utility.renderDate_MMDDYYY(thankYouDts)}
            </div>
            <div className="thank-you-media-container">
                <img
                    className="thank-you-media"
                    src={gamificationActive
                        ? firstTierThankYouMedia
                        : !!thankYouImageUrl
                            ? thankYouImageUrl
                            : thankYouMedia
                    }
                    alt={content.IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA}
                />
                {gamificationActive && !!validationCode
                    ? <RenderRedeemQrCode validationCode={validationCode} brandId={brandId}/>
                    : null
                }
            </div>
            <div className="main-container">
                <div className={`big-text${usePromo ? " use-promo" : ""}`}>
                    {gamificationActive
                        ? <>
                            <div className="bold">{Utility.capitalize(content.THANKS_AND_CONGRATS)}!</div>
                            <div>{Utility.capitalize(content.TO_CLAIM_YOUR_REWARD)}:</div>
                            <div>{content.TAKE_A_SCREENSHOT_SENTENCE}</div>
                        </>
                        : usePromo
                            ? <>
                                <div>{Utility.capitalize(content.THANK_YOU_FOR_SHARING_YOUR_EXPERIENCE)}!</div>
                                <div>{Utility.createVariableSentenceSpan(Utility.capitalize(content.USE_THIS_CODE_$$PROMO_CODE$$_TO_GET_$$CUSTOM_REWARD$$), new Map([["promoCode", currentPromoCode], ["customReward", customThankYouRewardText]]))}!</div>
                            </>
                            : hasMainRewardAsRaffle
                                ? <span>{Utility.capitalize(content[showForgotToAddContact ? "DONT_MISS_YOUR_CHANCE_TO_WIN" : "CONTACT_ENTERED_INTO_RAFFLE_SENTENCE"])}</span>
                                : <span>{Utility.capitalize(
                                    customThankYouText
                                        ? customThankYouText
                                        : content[!industryCode
                                            ? "GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US"
                                            : "GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US_ALT"
                                        ]
                                )}</span>
                    }
                </div>
                {hasMainRewardAsRaffle && showForgotToAddContact
                    ? <div
                        className="forgot-to-add-contact additional-text hint styled-link"
                        onClick={() => goToNewStage(2, 3)}
                    >
                        <span>{Utility.capitalize(content.SHARE_YOUR_CONTACT_INFO_TO_ENTER_RAFFLE)}</span>
                        <div className="link-underline"/>
                    </div>
                    : null
                }
                {promoTerms
                    ? <div className="additional-text tips-container hint">
                        {promoTerms}
                    </div>
                    : null
                }
                <div className="btn-container">
                    {usePromo
                        ? <StyledButton
                            type="main"
                            onClick={handleRedeemNow}
                        >
                            {Utility.capitalize(content.REDEEM_NOW)}
                        </StyledButton>
                        : null
                    }
                    <StyledButton
                        type={usePromo ? "secondary" : "main"}
                        onClick={goToViewMyShares}
                    >
                        {Utility.capitalize(content.SHARE_MORE)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;