import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledButton = (props) => {
    const {
        className,
        children,
        type,   //"main", "secondary", "text"
        icon,
        isDisabled,
        noBorder,
        onClick,
        brandColorOverride,
    } = props;

    return (
        <button
            style={!!brandColorOverride && !isDisabled
                ? type === "main"
                    ? {background: brandColorOverride}
                    : {color: brandColorOverride, borderColor: brandColorOverride}
                : null
            }
            className={`styled-button middle-text ${type}${noBorder ? " no-border" : ""}${isDisabled ? " disabled" : ""}${className ? " " + className : ""}`}
            onClick={isDisabled ? null : onClick}
        >
            {icon || null}
            {children}
        </button>
    );
};

export default StyledButton;