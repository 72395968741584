import React, {createContext, useState, useEffect} from "react";
import enums from '../enums.json';
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";

const {languages} = enums;

export const routes = {
    ROOT: "/",
    PROMO_VALIDATION: "/redeem-code",
    WILDCARD: "/*",
    NOT_FOUND: "/404"
}

export const GeneralContext = createContext(null);

export const GeneralProvider = (props) => {
    const [contentLoading, setContentLoading] = useState(true);
    const [appLang, setAppLang] = useState(languages.ENGLISH);
    const content = language[appLang];
    // const [langDir, setLangDir] = useState("ltr");   //save to use if needed for rtl languages

    const [uploadStage, setUploadStage] = useState(1);
    const [returnToStage, setReturnToStage] = useState(1);
    const [currentUploads, setCurrentUploads] = useState([]);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    const [userHandle, setUserHandle] = useState("");
    const [brandIsDoingCgc, setBrandIsDoingCgc] = useState(true);
    const [name, setName] = useState("");
    const [brandId, setBrandId] = useState("");
    const [handle, setHandle] = useState("");
    const [googleLink, setGoogleLink] = useState("");
    const [incentiveStatement, setIncentiveStatement] = useState(content.GET_DISCOUNT_SENTENCE);
    const [secondIncentive, setSecondIncentive] = useState("");
    const [industryCode, setIndustryCode] = useState(0);
    const [hasMainRewardAsRaffle, setHasMainRewardAsRaffle] = useState(false);
    const [thankYouImageUrl, setThankYouImageUrl] = useState("");
    const [welcomeMsg, setWelcomeMsg] = useState("");
    const [coverImageUrl, setCoverImageUrl] = useState("");
    const [gamificationActive, setGamificationActive] = useState(false);
    const [imagesProcessedId, setImagesProcessedId] = useState("");
    const [submitInitialized, setSubmitInitialized] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [hasBeenAskedToShareVideo, setHasBeenAskedToShareVideo] = useState(false);
    const [imagesRejectedNotificationOpen, setImagesRejectedNotificationOpen] = useState(false);
    const [sessionStartDts, setSessionStartDts] = useState(new Date());
    const [thankYouDts, setThankYouDts] = useState(new Date());
    const [lastSessionUpdate, setLastSessionUpdate] = useState(new Date());
    const [howHeardAboutUsResponse, setHowHeardAboutUsResponse] = useState("");
    const [validationCode, setValidationCode] = useState("");
    const [isNewMediaUploaded, setIsNewMediaUploaded] = useState(false);
    const [currentPromoCode, setCurrentPromoCode] = useState("");
    const [customThankYouText, setCustomThankYouText] = useState("");
    const [customThankYouRewardText, setCustomThankYouRewardText] = useState("");
    const [promoTerms, setPromoTerms] = useState("");
    const [orderingAppUrl, setOrderingAppUrl] = useState("");

    const updateSession = () => setLastSessionUpdate(new Date());
    const trackerTicker = setInterval(updateSession, 5000);

    // useEffect(() => {   //to test scheduled functions locally
    //     Utility.httpCall("onboardNewBrands", {}).then((response) => {
    //         console.log("onboardNewBrands successfully run at " + new Date().toJSON());
    //     })
    // }, []);

    useEffect(() => {
        return () => {
            clearInterval(trackerTicker);
            setSessionStartDts(new Date());
        }
    }, []);

    useEffect(() => {
        if (new Date(sessionStartDts).valueOf() + 60 * 60 * 1000 < new Date(lastSessionUpdate).valueOf()) {
            setReturnToStage(1);
            setUploadStage(1);
            setSessionStartDts(new Date());
        }
    }, [lastSessionUpdate]);

    useEffect(() => {
        if (submitInitialized && !!imagesProcessedId) {
            updateCgcUserData().catch((e) => console.error(e));
        }
    }, [submitInitialized, imagesProcessedId]);

    const switchLanguage = language => {
        setAppLang(language);
        // setLangDir(language === languages.HEBREW || language === languages.ARABIC ? "rtl" : "ltr")   //save to use if needed for rtl languages
    };

    const goToNewStage = (returnToStage, newStage) => {
        setReturnToStage(returnToStage);
        setUploadStage(newStage);
    }

    const uploadMedia = async (files) => {
        const newUploads = [];
        const saveMediaToStoragePromises = [];

        files.forEach((file) => {

            if (Utility.isFileTooLarge(file)) {
                setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
                return;
            }

            const metaData = {
                type: file.type,
                size: file.size,
                fileExt: file.name.split(".").pop().toLowerCase(),
            }
            const tempUrl = URL.createObjectURL(file);

            const tempUpload = {
                metaData,
                tempUrl
            }

            const rootPath = `brands/${brandId}/initial-uploads`;
            saveMediaToStoragePromises.push(Utility.saveMediaToStorage(file, metaData, rootPath, name));
            newUploads.push(tempUpload);
        });

        setContentLoading(true);
        const saveMediaToStorageResults = await Promise.all(saveMediaToStoragePromises);
        setContentLoading(false);
        const updatedCurrentUploads = [...currentUploads];

        newUploads.forEach((updatedUpload, index) => {
            const uploadId = saveMediaToStorageResults[index]?.uploadId;
            const filePath = saveMediaToStorageResults[index]?.filePath;

            if (!!uploadId && !!filePath) {
                updatedUpload.metaData = saveMediaToStorageResults[index].metaData || updatedUpload.metaData;
                updatedUpload.downloadUrl = saveMediaToStorageResults[index].downloadUrl;
                updatedCurrentUploads.push({
                    ...updatedUpload,
                    uploadId,
                    filePath,
                });
            } else {
                setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE)
            }
        });

        setCurrentUploads(updatedCurrentUploads);
    }

    const uploadBlob = async (blob, fileExt) => {
        if (Utility.isFileTooLarge(blob.size, fileExt)) {
            setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
            return;
        }

        const metaData = {
            type: blob.type,
            size: blob.size,
            fileExt,
        }
        const tempUrl = URL.createObjectURL(blob);

        const rootPath = `brands/${brandId}/initial-uploads`;
        setContentLoading(true);
        const saveMediaToStorageResult = await Utility.saveMediaToStorage(blob, metaData, rootPath, name);
        setContentLoading(false);

        const uploadId = saveMediaToStorageResult?.uploadId;
        const filePath = saveMediaToStorageResult?.filePath;

        if (!!uploadId && filePath) {
            const tempUpload = {
                metaData: saveMediaToStorageResult.metaData || metaData,
                tempUrl,
                uploadId,
                filePath,
                downloadUrl: saveMediaToStorageResult?.downloadUrl
            }

            setCurrentUploads([...currentUploads, tempUpload]);
        } else {
            setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE)
        }
    }

    const checkUploadsIncludeVideos = () => {
        let uploadsIncludeVideos = false;
        currentUploads.forEach((upload) => {
            if (upload?.metaData?.type?.startsWith('video/')) {
                uploadsIncludeVideos = true;
            }
        });

        return uploadsIncludeVideos;
    }

    const clearTempMedia = () => {
        const updatedUploads = [];
        currentUploads.forEach((upload) => {
            if (!!upload.tempUrl) {
                URL.revokeObjectURL(upload.tempUrl);
            } else updatedUploads.push(upload);
        });

        setCurrentUploads(updatedUploads);
    }

    const getNewMediaData = () => {
        const newMediaData = [];
        const alreadyAcceptedMedia = [];
        currentUploads.forEach((currentUpload) => {
            if (!!currentUpload.imageId) {
                alreadyAcceptedMedia.push(currentUpload);
            } else if (!!currentUpload.uploadId) {
                newMediaData.push(currentUpload);
            }
        });


        if (newMediaData.length === 0) {
            if (alreadyAcceptedMedia.length === 0) {
                setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE);
            }
            return [];
        }

        return newMediaData;
    }

    const saveAndFilterImages = async () => {
        let success;
        const newMediaData = getNewMediaData();
        const hasNewMedia = !!newMediaData?.length;

        if (!hasNewMedia) {
            return;
        } else setIsNewMediaUploaded(true);

        setImagesProcessedId("");

        try {
            const response = await Utility.httpCall("submitCgc", {
                brandId,
                brandName: name,
                newMediaData,
                industryCode
            });

            if (!response?.data?.mediaProcessedId) {
                success = false;
            } else {
                setImagesProcessedId(response.data.mediaProcessedId);
                setCurrentUploads(response.data.successfullyLoadedAndFilteredMedia || []);
                success = true;
            }
        } catch (e) {
            console.error(e);
            success = false;
        }

        if (!success) {
            setSubmitInitialized(false);
            setContentLoading(false);
            setErrorMsg(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE);
        }
    }

    const acceptTermsAndConditions = () => !(!hasAcceptedTerms || currentUploads.length < 1);

    const goToShareMore = () => {
        const readyToGoToNext = acceptTermsAndConditions();

        if (readyToGoToNext) {
            goToNewStage(2, 3);
            saveAndFilterImages().then();
        }
    }

    const goBackToKeepSnapping = () => goToNewStage(1, 2);

    const goToViewMyShares = (returnToStage, newStage) => {
        if (!returnToStage || !newStage) {
            setReturnToStage(4);
            setUploadStage(5);
        } else goToNewStage(returnToStage, newStage);
    }

    const handleSubmitData = async () => {
        setContentLoading(true);
        setSubmitInitialized(true);
    }

    const updateCgcUserData = async () => {
        let success;

        if (imagesProcessedId === "allImagesFailed") {
            success = true;
        } else {
            try {
                const response = await Utility.httpCall("updateCgcUserData", {
                    brandId,
                    mediaProcessedId: imagesProcessedId,
                    userEmail,
                    userHandle,
                    howHeardAboutUsResponse,
                    isNewMediaUploaded,
                });

                if (!!response?.data) {
                    success = true;
                    setValidationCode(response.data.validationCodeId || validationCode)
                } else success = false;
            } catch (e) {
                console.error(e);
                success = false;
            }
        }

        setContentLoading(false);
        setSubmitInitialized(false);
        setIsNewMediaUploaded(false);

        if (!!success) {
            setThankYouDts(new Date());

            if (!gamificationActive) {
                if (imagesProcessedId === "allImagesFailed") {
                    setImagesRejectedNotificationOpen(true);
                } else {
                    goToNewStage(3, 4);
                }
            } else {
                const resultPage = getResultPageFromProcessedId();
                goToNewStage(3, resultPage);
            }
        } else {
            setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
        }
    }

    const getResultPageFromProcessedId = () => {
        if (!imagesProcessedId) {
            return 4;
        }

        const [processedId, topTier] = imagesProcessedId.split("_");
        const hasTopTierMedia = !!topTier && topTier === "toptier";

        if (gamificationActive && processedId === "allImagesFailed") {
            return 9;
        } else if (gamificationActive && !hasTopTierMedia) {
            return 8;
        } else return 4;
    }

    const deleteImage = async (imageId, filePath) => {
        try {
            const response = await Utility.httpCall("deleteMedia", {imageId, filePath});
            if (!response.data) {
                console.error(`Error deleting ${imageId}`);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getBrandData = async (id) => {
        if (!id) {
            setBrandIsDoingCgc(false);
            return
        }

        const response = await Utility.httpCall("getBrandProps", {
            brandId: id,
            props: [
                "isCgcEnabled",
                "brandName",
                "brandHandle",
                "googleReviewLink",
                "cgcIncentive",
                "secondIncentive",
                "industryCode",
                "isGamificationMode",
                "mainRewardIsRaffle",
                "customThankYouUrl",
                "customWelcome",
                "customCoverImageUrl",
                "activePromoCode",
                "customThankYou",
                "customThankYouReward",
                "customPromoTerms",
                "orderingAppLink",
            ]
        });
        const {
            isCgcEnabled,
            brandName,
            brandHandle,
            googleReviewLink,
            cgcIncentive,
            secondIncentive,
            industryCode,
            isGamificationMode,
            mainRewardIsRaffle,
            customThankYouUrl,
            customWelcome,
            customCoverImageUrl,
            activePromoCode,
            customThankYou,
            customThankYouReward,
            customPromoTerms,
            orderingAppLink,
        } = response.data;

        setBrandIsDoingCgc(!!isCgcEnabled);
        setName(brandName);
        setHandle(brandHandle);
        setGoogleLink(googleReviewLink || "");
        setIncentiveStatement(cgcIncentive || content.GET_DISCOUNT_SENTENCE);
        setSecondIncentive(secondIncentive);
        setBrandId(id);
        setIndustryCode(industryCode || 0);
        setGamificationActive(!!isGamificationMode);
        setHasMainRewardAsRaffle(!!mainRewardIsRaffle);
        setThankYouImageUrl(customThankYouUrl);
        setWelcomeMsg(customWelcome);
        setCoverImageUrl(customCoverImageUrl);
        setCurrentPromoCode(activePromoCode);
        setCustomThankYouText(customThankYou);
        setCustomThankYouRewardText(customThankYouReward);
        setPromoTerms(customPromoTerms);
        setOrderingAppUrl(orderingAppLink);
    }

    return (
        <GeneralContext.Provider value={{
            contentLoading,
            appLang,
            uploadStage,
            returnToStage,
            currentUploads,
            hasAcceptedTerms,
            brandId,
            name,
            userEmail,
            userHandle,
            brandIsDoingCgc,
            handle,
            googleLink,
            incentiveStatement,
            secondIncentive,
            industryCode,
            hasMainRewardAsRaffle,
            thankYouImageUrl,
            welcomeMsg,
            coverImageUrl,
            gamificationActive,
            submitInitialized,
            errorMsg,
            hasBeenAskedToShareVideo,
            imagesRejectedNotificationOpen,
            thankYouDts,
            howHeardAboutUsResponse,
            validationCode,
            currentPromoCode,
            customThankYouText,
            customThankYouRewardText,
            promoTerms,
            orderingAppUrl,

            setContentLoading,
            setAppLang,
            setUploadStage,
            setReturnToStage,
            setCurrentUploads,
            setHasAcceptedTerms,
            setUserEmail,
            setUserHandle,
            setSubmitInitialized,
            setErrorMsg,
            setHasBeenAskedToShareVideo,
            setImagesRejectedNotificationOpen,
            setThankYouDts,
            setHowHeardAboutUsResponse,

            switchLanguage,
            uploadMedia,
            uploadBlob,
            clearTempMedia,
            goToShareMore,
            goBackToKeepSnapping,
            getBrandData,
            deleteImage,
            checkUploadsIncludeVideos,
            goToViewMyShares,
            goToNewStage,
            handleSubmitData,
            getResultPageFromProcessedId,
        }}>
            {props.children}
        </GeneralContext.Provider>
    )
}